import React from 'react';
import './App.css';

function MyButton({ title }: { title: string }) {
  return (
      <button>{title}</button>
  );
}


export default App;

function App() {
  function getListButton(text: string, href: string, download: boolean) {
    return <div className="Button-wrapper"> <a
        className="App-link"
        href={href}
        target= "_blank"
        download={download ? href : null}
        rel="noopener noreferrer"
    >
      {text}
    </a></div>
        ;
  }
  return (
      <div className="App">
        <header className="App-header">
          <div className="Button-list">
            {getListButton("Instagram", "https://www.instagram.com/jonathaneichhain/", false)}
            {getListButton("Download Kontakt", "/Jonathan_von_Eichhain.vcf", true)}
          </div>
        </header>
      </div>
  );
}
